/**
 * Be very careful when changing this function.
 *
 * It doesn't look too complex, but it's fragile because Next.js relies on window.history.* internals
 * to manage client-side navigation.
 *
 * You might also think we should use router.replace() instead of history.replaceState() to do things the
 * "Next.js way", but that's not currently an option because a shallow router.replace() breaks the back button.
 *
 * @see https://github.com/vercel/next.js/issues/42804
 */
export const setDeepLinkUrlParams = (
  data: URLSearchParams | Record<string, string>
) => {
  const searchParams =
    data instanceof URLSearchParams ? data : new URLSearchParams(data);

  const params = searchParams.toString();
  const currentSearch = window.location.search;

  if (!params && !currentSearch) return;

  const currentPathname = window.location.pathname;
  const path = params ? `${currentPathname}?${params}` : currentPathname;

  // It's vital to preserve the current history state, because it contains values that
  // Next.js uses to support client-side navigation via the back/forward buttons.
  const currentHistoryState = window.history.state;

  // Here we're manually overriding the current history state, which Next.js uses internally.
  // This is a bit of a high risk change, and should be revisited when upgrading Next.js.
  const newHistoryState = {
    ...currentHistoryState,
    as: path,
    url: path,
  };

  // Update query params, without triggering a reload
  history.replaceState(newHistoryState, '', path);
};

/**
 * Receives a hashmap, and returns an inverted map.
 *
 * @example
 * const hashMap = { NAME: 'name', USER_TYPE: 'type' }
 * const reversed = reverseRecord(hashMap)
 * const value1 = reversed['name'] // NAME
 * const value2 = reversed['type'] // USER_TYPE
 */
export function reverseRecord<
  Key extends PropertyKey,
  Value extends PropertyKey,
>(input: Record<Key, Value>) {
  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => [value, key])
  ) as Record<Value, Key>;
}

/**
 * When persisting enums to the URL, we usually want to show a user friendly value like "creator" in the URL,
 * and a related constant like "CREATOR_PUBLIC_PROFILE" in the code.
 *
 * This utility receives a hashmap of constants to user-friendly, and returns two functions which can be used to:
 * - convert a constant to a user-friendly value
 * - convert a user-friendly value back to a constant
 *
 * @example
 * const mapper = createBidirectionalMap({ USER_DISPLAY_NAME: 'name', USER_TYPE: 'type' })
 *
 * const url = mapper.toUrl('USER_DISPLAY_NAME') // 'name'
 * const value = mapper.toValue('name') // 'USER_DISPLAY_NAME'
 */
export function createBidirectionalMap<
  Key extends PropertyKey,
  Value extends PropertyKey,
>(valueToUrlMap: Record<Key, Value>) {
  const urlToValueMap = reverseRecord(valueToUrlMap);

  return {
    toUrl: (value: keyof typeof valueToUrlMap) => valueToUrlMap[value],
    toValue: (urlValue: keyof typeof urlToValueMap) => urlToValueMap[urlValue],
  };
}
