import { styled } from '@f8n-frontend/stitches';

import Text from 'components/base/Text';
import ModerationIcon from 'components/trust-safety/ModerationIcon';

import { ModeratedModerationStatus } from 'types/Moderation';

export type ModeratedCopyMap = Record<ModeratedModerationStatus, string>;

interface ModerationBannerProps {
  status: ModeratedModerationStatus;
  messages: ModeratedCopyMap;
  variant?: 'fixed' | 'normal';
}

export default function ModerationBanner(props: ModerationBannerProps) {
  const { status, messages, variant = 'fixed' } = props;

  return (
    <ModerationBannerRoot variant={variant}>
      <ModerationIcon status={status} />
      <Text
        weight="semibold"
        lineHeight={0}
        size={2}
        css={{ marginLeft: '$2' }}
      >
        {messages[status]}
      </Text>
    </ModerationBannerRoot>
  );
}

const ModerationBannerRoot = styled('div', {
  backgroundColor: '$white90',
  backdropFilter: 'blur(10px)',
  paddingX: '$4',
  paddingY: '$7',
  boxShadow: '$regular1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  svg: {
    color: '$black40',
  },

  variants: {
    variant: {
      fixed: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10000,
      },
      normal: {},
    },
  },

  defaultVariants: {
    variant: 'fixed',
  },
});
