import ReactMarkdown from 'react-markdown';
import remarkBreaksPlugin from 'remark-breaks';

interface MarkdownProps {
  children: string;
}

function Markdown(props: MarkdownProps) {
  return (
    <ReactMarkdown
      disallowedElements={['img']}
      remarkPlugins={[remarkBreaksPlugin]}
    >
      {props.children}
    </ReactMarkdown>
  );
}

export default Markdown;
