import report from 'lib/report';

import { AlgoliaSearchIndex } from 'types/Algolia';
import { MarketAvailability } from 'types/MarketAvailability';
import { NftSortOrder } from 'types/Nft';

// a mapping of which search indexes to use when market availability is set
const artworkIndexByOrderAndMarketMap: Record<
  NftSortOrder,
  Record<MarketAvailability, AlgoliaSearchIndex>
> = {
  DEFAULT: {
    HAS_ACTIVE_BUY_NOW: 'artworks_sort_buy_now_price_asc',
    HAS_ACTIVE_OFFER: 'artworks_sort_offer_price_asc',
    LIVE_AUCTION: 'artworks_sort_auction_live_price_asc',
    RESERVE_NOT_MET: 'artworks_sort_auction_reserve_price_asc',
  },
  PRICE_ASC: {
    HAS_ACTIVE_BUY_NOW: 'artworks_sort_buy_now_price_asc',
    HAS_ACTIVE_OFFER: 'artworks_sort_offer_price_asc',
    LIVE_AUCTION: 'artworks_sort_auction_live_price_asc',
    RESERVE_NOT_MET: 'artworks_sort_auction_reserve_price_asc',
  },
  PRICE_DESC: {
    HAS_ACTIVE_BUY_NOW: 'artworks_sort_buy_now_price_desc',
    HAS_ACTIVE_OFFER: 'artworks_sort_offer_price_desc',
    LIVE_AUCTION: 'artworks_sort_auction_live_price_desc',
    RESERVE_NOT_MET: 'artworks_sort_auction_reserve_price_desc',
  },
  MINT_DATE_ASC: {
    HAS_ACTIVE_BUY_NOW: 'artworks_sort_date_minted_asc',
    HAS_ACTIVE_OFFER: 'artworks_sort_date_minted_asc',
    LIVE_AUCTION: 'artworks_sort_date_minted_asc',
    RESERVE_NOT_MET: 'artworks_sort_date_minted_asc',
  },
  MINT_DATE_DESC: {
    HAS_ACTIVE_BUY_NOW: 'artworks_sort_date_minted_desc',
    HAS_ACTIVE_OFFER: 'artworks_sort_date_minted_desc',
    LIVE_AUCTION: 'artworks_sort_date_minted_desc',
    RESERVE_NOT_MET: 'artworks_sort_date_minted_desc',
  },
};

// a mapping of Algolia indices
const artworkIndexMap: Record<NftSortOrder, AlgoliaSearchIndex> = {
  DEFAULT: 'artworks_sort_default',
  PRICE_ASC: 'artworks_sort_default_price_asc',
  PRICE_DESC: 'artworks_sort_default_price_desc',
  MINT_DATE_ASC: 'artworks_sort_date_minted_asc',
  MINT_DATE_DESC: 'artworks_sort_date_minted_desc',
};

/**
 * maps the current market filters to the correct sort index
 */
export function mapMarketAvailabilityToAlgoliaSearchIndex(
  marketAvailability: MarketAvailability | null,
  sortOrder: NftSortOrder
): AlgoliaSearchIndex {
  if (marketAvailability) {
    const marketIndexMap = artworkIndexByOrderAndMarketMap[sortOrder];

    if (marketIndexMap) {
      const index = marketIndexMap[marketAvailability];
      if (index) {
        return index;
      }
    }
  }

  const index = artworkIndexMap[sortOrder];
  if (index) {
    return index;
  }

  report(new Error('Failed to derive expected algolia index'), {
    extra: {
      sortOrder,
      marketAvailability,
    },
  });

  return artworkIndexMap['DEFAULT'];
}
