import { ChangeEvent } from 'react';

import Button from 'components/base/Button';
import ChevronButton from 'components/base/ChevronButton';
import Text from 'components/base/Text';
import { Select } from 'components/forms/fields/SelectField';

import SortIcon from 'assets/icons/sort-icon.svg';
import { useNftFilters } from 'hooks/filters/use-nft-filters';

import { NftSortOrder } from 'types/Nft';

type SortOption = {
  label: string;
  value: NftSortOrder;
};

interface NftsSortProps {
  cacheKey: string;
}

const OPTIONS: SortOption[] = [
  {
    label: 'Most Active',
    value: 'DEFAULT',
  },
  {
    label: 'Price: Lowest',
    value: 'PRICE_ASC',
  },
  {
    label: 'Price: Highest',
    value: 'PRICE_DESC',
  },
  {
    label: 'Newest',
    value: 'MINT_DATE_DESC',
  },
  {
    label: 'Oldest',
    value: 'MINT_DATE_ASC',
  },
];
export default function NftsSort(props: NftsSortProps) {
  const { cacheKey } = props;
  const { filters, setFilters } = useNftFilters(cacheKey);

  const handleSort = (ev: ChangeEvent<HTMLSelectElement>) => {
    const sortOrder = ev.target.value as NftSortOrder;
    setFilters({ sortOrder });
  };

  const activeOption =
    OPTIONS.find((index) => index.value === filters.sortOrder) ||
    (OPTIONS[0] as SortOption);

  const select = (
    <Select
      onBlur={handleSort}
      onChange={handleSort}
      value={activeOption.value}
    >
      {OPTIONS.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </Select>
  );

  return (
    <>
      {/* TODO: make this keyboard accessible so that
      pressing enter triggers the select menu to open */}
      <Button
        variant="outline"
        icon="standalone"
        size={0}
        css={{
          flexShrink: 0,
          overflow: 'hidden',
          '@bp3': {
            display: 'none',
          },
        }}
      >
        <SortIcon />
        {select}
      </Button>
      <ChevronButton
        css={{
          minWidth: 160,
          justifyContent: 'space-between',
          '@bp3-max': {
            display: 'none',
          },
        }}
        size={0}
        tabIndex={0}
      >
        {select}
        <Text>{activeOption.label}</Text>
      </ChevronButton>
    </>
  );
}
