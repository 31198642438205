import { styled } from '@f8n-frontend/stitches';
import { keepPreviousData } from '@tanstack/react-query';

import Box from 'components/base/Box';
import ButtonWithCount from 'components/base/ButtonWithCount';

import { useNftFilters } from 'hooks/filters/use-nft-filters';
import { AlgoliaSearchIndexOptions } from 'hooks/queries/algolia/shared';
import { useAlgoliaArtworksAggregates } from 'hooks/queries/algolia/use-algolia-artworks';
import { NFTS_PAGE_CACHE_KEY } from 'lib/constants';
import { abbreviateValue } from 'utils/formatters';

import { MarketAvailability } from 'types/MarketAvailability';

type MarketAvailabilityFilterProps =
  | { contractAddress: string }
  | { publicKey: string }
  | { cacheKey: typeof NFTS_PAGE_CACHE_KEY };

function getMarketAvailabilityQueryCacheKey(
  props: MarketAvailabilityFilterProps
): string {
  if ('contractAddress' in props) {
    return props.contractAddress;
  } else if ('publicKey' in props) {
    return props.publicKey;
  } else {
    // Use the cache key prop when we don't want to apply any filter to the sort and instead just
    // use a generic cache key (at this point only NFTS_PAGE_CACHE_KEY on /nfts is supported)
    return props.cacheKey;
  }
}

export default function MarketAvailabilityFilter(
  props: MarketAvailabilityFilterProps
) {
  const cacheKey = getMarketAvailabilityQueryCacheKey(props);
  const { filters, setFilters } = useNftFilters(cacheKey);

  const { data: artworksAggregatesData } = useAlgoliaArtworksAggregates(
    cacheKey,
    {
      // This value is hardcoded to ensure counts remain consistent when different filters are applied
      searchIndex: 'artworks_sort_default',
      options: {
        hitsPerPage: 0,
        facets: ['marketAvailability'],
        facetFilters: [
          // Baseline filters
          'moderationStatus:ACTIVE',
          'isDeleted:false',
          [
            'marketAvailability:LIVE_AUCTION',
            'marketAvailability:HAS_ACTIVE_BUY_NOW',
            'marketAvailability:HAS_ACTIVE_OFFER',
            'marketAvailability:RESERVE_NOT_MET',
          ],
          // Filters for the current page
          'contractAddress' in props
            ? `collection.contractAddress:${props.contractAddress}`
            : null,
          'publicKey' in props ? `creator.publicKey:${props.publicKey}` : null,

          // Filters based on other UI filters applied on the page
          Object.entries(filters.collections).reduce((prev, curr) => {
            const [collectionId, isSelected] = curr;
            if (!isSelected) return prev;
            // @ts-expect-error null-checks
            return prev.concat(`collection.contractAddress:${collectionId}`);
          }, []),
        ].filter(Boolean) as AlgoliaSearchIndexOptions['facetFilters'],
      },
    },
    { placeholderData: keepPreviousData }
  );

  const marketAvailability = artworksAggregatesData?.facets?.marketAvailability;

  type AvailabilityButton = {
    value: MarketAvailability;
    label: string;
  };

  const availabilityModes: AvailabilityButton[] = [
    {
      value: 'LIVE_AUCTION',
      label: 'Live Auction',
    },
    {
      value: 'HAS_ACTIVE_BUY_NOW',
      label: 'Buy Now',
    },
    {
      value: 'RESERVE_NOT_MET',
      label: 'Reserve Price',
    },
    {
      value: 'HAS_ACTIVE_OFFER',
      label: 'Active Offers',
    },
  ];

  return (
    <>
      {availabilityModes.map((availabilityMode) => {
        const count = marketAvailability?.[availabilityMode.value] || 0;
        return (
          <ButtonWithCount.Root
            key={availabilityMode.value}
            disabled={!count}
            size={0}
            type="button"
            variant="outline"
            pressed={filters.marketAvailability === availabilityMode.value}
            onClick={() => {
              setFilters({ marketAvailability: availabilityMode.value });
            }}
          >
            {availabilityMode.label}
            <ButtonWithCount.Count>
              {abbreviateValue(count)}
            </ButtonWithCount.Count>
          </ButtonWithCount.Root>
        );
      })}
    </>
  );
}

export const MarketAvailabilityContainer = styled(Box, {
  // TODO: find a way to reuse these styles, with dynamic media queries
  gap: '$2',
  width: '100%',
  gridTemplateColumns: 'repeat(2, 1fr)',

  '@bp2-max': {
    display: 'grid',
    flexGrow: 1,

    [`& ${ButtonWithCount.Root}`]: {
      justifyContent: 'space-between',
    },
  },

  '@bp0': {
    display: 'flex',
  },

  '@bp1': {
    display: 'grid',
  },

  '@bp2': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 'auto',
  },
});
