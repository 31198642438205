import { styled } from '@f8n-frontend/stitches';
import { useMutation } from '@tanstack/react-query';
import { Formik, Form } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation } from 'react-use';

import SpinnerStroked from 'components/SpinnerStroked';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Modal from 'components/base/Modal';
import TextAreaField from 'components/forms/fields/TextAreaField';
import TextField from 'components/forms/fields/TextField';
import useNotifications from 'state/stores/notifications';

import report from 'lib/report';
import { sendReport } from 'queries/admin/admin';
import { ReportFormSchema } from 'schemas/forms/admin';

import { PageType, ReportReason } from 'types/Report';
import { Maybe } from 'types/utils';

import { formFieldsData } from './data';

interface ReportFormProps {
  email: Maybe<string> | undefined;
  publicKey: string;
  reportedPublicKey: string;
  onSubmit: () => void;
  onBack: () => void;
  onClose: () => void;
  reason: ReportReason;
  pageType: PageType;
}

export default function ReportForm(props: ReportFormProps) {
  const {
    email,
    publicKey,
    onSubmit,
    onBack,
    onClose,
    reason,
    pageType,
    reportedPublicKey,
  } = props;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { href } = useLocation();
  const notifications = useNotifications();

  const selectedReason = formFieldsData.find((d) => d.type === reason);

  const {
    mutateAsync: sendReportMutation,
    isPending: sendReportLoading,
    isSuccess: sendReportSuccess,
  } = useMutation({
    mutationFn: sendReport,
    onSettled: () => {
      onSubmit();
    },
  });

  const handleOnSubmit = async (props: {
    email: string;
    issue: string;
    url: string;
    originalUrl: string;
  }) => {
    try {
      if (!executeRecaptcha) {
        const message = 'Failed to submit report';
        report(new Error(message));
        notifications.show.error({ message });
        return;
      }
      const recaptchaToken = await executeRecaptcha('report_form');
      await sendReportMutation({
        ...props,
        issueReason: reason,
        reporterPublicKey: publicKey,
        pageType,
        recaptchaToken,
        reportedPublicKey,
      });
    } catch (error) {
      report(error);
      notifications.show.error({
        message: 'Failed to submit report',
      });
    }
  };

  const isNonFormLayout = reason === 'IP';

  if (isNonFormLayout) {
    return (
      <Modal.Window
        header={<Modal.Header onBackClick={onBack} />}
        footer={
          <Modal.Footer>
            <Button size={1} variant="outline" onClick={onClose}>
              Done
            </Button>
          </Modal.Footer>
        }
      >
        {selectedReason && (
          <Modal.BodyTitle
            description={<selectedReason.description />}
            title={selectedReason.title}
          />
        )}
      </Modal.Window>
    );
  }

  return (
    <Formik
      initialValues={{
        email: email,
        url: href ?? '',
        originalUrl: '',
        issue: '',
      }}
      enableReinitialize
      validationSchema={ReportFormSchema}
      onSubmit={handleOnSubmit}
    >
      <Form>
        <Modal.Window
          header={<Modal.Header onBackClick={onBack} />}
          footer={
            <Modal.Footer>
              <Button
                type="submit"
                variant="primary"
                disabled={sendReportLoading || sendReportSuccess}
              >
                <ButtonInner>
                  {(sendReportLoading || sendReportSuccess) && (
                    <Box css={{ position: 'absolute', left: -26 }}>
                      <SpinnerStroked size={18} />
                    </Box>
                  )}{' '}
                  Submit Report
                </ButtonInner>
              </Button>
            </Modal.Footer>
          }
        >
          {selectedReason && (
            <Modal.BodyTitle
              description={<selectedReason.description />}
              title={selectedReason.title}
            />
          )}
          <FieldWrapper>
            <TextField name="email" placeholder="Email" label="Email" />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              name="url"
              placeholder="https://foundation.app/..."
              label="URL to report"
            />
          </FieldWrapper>
          {selectedReason?.originalArtworkField && (
            <FieldWrapper>
              <TextField
                name="originalUrl"
                placeholder="https://..."
                label={selectedReason.originalFieldLabel}
              />
            </FieldWrapper>
          )}
          <TextAreaField.Formik
            name="issue"
            placeholder="Describe why this page should is being reported."
            label="Issue"
            rows={6}
          />
        </Modal.Window>
      </Form>
    </Formik>
  );
}

const FieldWrapper = styled('div', {
  marginY: '$5',
});

const ButtonInner = styled('div', {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
});
