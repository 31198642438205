import { SearchResponse } from '@algolia/client-search';
import { InfiniteData, keepPreviousData } from '@tanstack/react-query';
import {
  useInfiniteQuery,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { groupWith } from 'ramda';

import { HASURA_DEFAULT_ITEMS_PER_PAGE } from 'lib/constants';
import { mapMarketAvailabilityToAlgoliaSearchIndex } from 'utils/algolia';
import { getFirstValue } from 'utils/helpers';
import { algoliaPaginator } from 'utils/react-query';

import { AlgoliaArtwork, AlgoliaSearchIndex } from 'types/Algolia';
import { NftFilters } from 'types/Nft';

import { AlgoliaSearchIndexOptions, getAlgoliaSearchResults } from './shared';

interface AlgoliaArtworksVariables {
  searchIndex: AlgoliaSearchIndex;
  options?: AlgoliaSearchIndexOptions;
}

export type AlgoliaArtworksSearch = SearchResponse<AlgoliaArtwork>;

export function useAlgoliaArtworksAggregates(
  cacheKey: string,
  variables: AlgoliaArtworksVariables,
  options: Pick<
    UseQueryOptions<AlgoliaArtworksSearch, Error>,
    'enabled' | 'placeholderData'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: useAlgoliaArtworksAggregates.getKey(cacheKey, variables),
    queryFn: () => {
      return getAlgoliaSearchResults<AlgoliaArtwork>({
        index: variables.searchIndex,
        query: '',
        options: variables.options || {},
      });
    },
  });
}

// TODO: the cache key containing variables causes
//  the UI to flickr when changing availability modes
useAlgoliaArtworksAggregates.getKey = (
  id: string,
  variables: AlgoliaArtworksVariables
) => ['AlgoliaArtworksAggregates', id, variables];

const algoliaArtworksGetKey = (
  id: string,
  variables: UseAlgoliaArtworksQueryVariables
) => ['useAlgoliaArtworks', id, variables];

type UseAlgoliaArtworksQueryVariables = {
  contractAddress: string;
  filters: NftFilters;
  showModeratedWorks: boolean;
};

type UseAlgoliaArtworksQueryOptions = {
  shouldPoll: (options: { algoliaArtworksCount: number }) => boolean;
};

type Data = SearchResponse<AlgoliaArtwork>;

/** We don't allow users to search via text currently */
const NULL_SEARCH_QUERY = '';

export default function useAlgoliaArtworks(
  variables: UseAlgoliaArtworksQueryVariables,
  options: {
    shouldPoll: UseAlgoliaArtworksQueryOptions['shouldPoll'];
    enabled: boolean;
  }
) {
  const { filters } = variables;
  const { shouldPoll } = options;

  return useInfiniteQuery<
    Data,
    Error,
    InfiniteData<Data>,
    ReturnType<typeof algoliaArtworksGetKey>,
    number | null
  >({
    queryKey: algoliaArtworksGetKey('artworks', variables),
    queryFn: ({ pageParam }) => {
      return getAlgoliaSearchResults<AlgoliaArtwork>({
        index: mapMarketAvailabilityToAlgoliaSearchIndex(
          filters.marketAvailability,
          filters.sortOrder
        ),
        query: NULL_SEARCH_QUERY,
        options: {
          facetFilters: [
            variables.showModeratedWorks
              ? [
                  'moderationStatus:ACTIVE',
                  'moderationStatus:UNDER_REVIEW',
                  'moderationStatus:TAKEDOWN_REQUESTED',
                  'moderationStatus:SUSPENDED',
                ]
              : 'moderationStatus:ACTIVE',
            'isDeleted:false',
            `collection.contractAddress:${variables.contractAddress}`,
            filters.media ? `distinctAssetKey:${filters.media}` : null,
            filters.marketAvailability
              ? `marketAvailability:${filters.marketAvailability}`
              : null,
            // TODO: split into helper function with comments
            ...groupWith((a, b) => {
              const [attrOne] = a.split(':');
              const [attrTwo] = b.split(':');
              return attrOne === attrTwo;
            }, filters.attributes),
          ].filter(Boolean) as AlgoliaSearchIndexOptions['facetFilters'],
          filters: filters.creatorPublicKey
            ? `creator.publicKey:${filters.creatorPublicKey}`
            : '',
          page: pageParam || 0,
          hitsPerPage: HASURA_DEFAULT_ITEMS_PER_PAGE,
        },
      });
    },
    getNextPageParam: algoliaPaginator.getNextPageParam,
    initialPageParam: algoliaPaginator.initialPageParam,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchInterval: (res) => {
      if (res.state.status === 'success') {
        const isPollingEnabled = shouldPoll({
          algoliaArtworksCount: getAlgoliaArtworksCount(res.state.data),
        });
        return isPollingEnabled ? 5000 : false;
      }
      return false;
    },
  });
}

export function getAlgoliaArtworksCount<Type extends Record<string, unknown>>(
  data: InfiniteData<SearchResponse<Type>, unknown> | undefined
) {
  if (!data) {
    return 0;
  }

  const firstPage = getFirstValue(data.pages);

  return firstPage ? firstPage.nbHits : 0;
}
