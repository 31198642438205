import { ChevronDownIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import Button from './Button';
import Text from './Text';

const TriggerIndicatorWrapper = styled('div');

const AccordionHeader = styled(AccordionPrimitive.Header, {
  svg: {
    transition: 'transform 300ms',
  },
  [`&[data-state=open] ${TriggerIndicatorWrapper} svg`]: {
    transform: 'rotate(180deg)',
  },
});

const AccordionContent = styled(AccordionPrimitive.Content);

const TriggerButton = styled(Button, {
  width: '100%',
  border: 'none',
  borderRadius: 0,
  borderTop: 'solid 1px $black5',
  justifyContent: 'space-between',

  color: '$black100',
  minWidth: 0,

  variants: {
    variant: {
      base: {
        display: 'flex',
      },
    },
    hasHover: {
      true: {
        '@hover': {
          '&:hover': {
            position: 'relative',
            backgroundColor: '$black5',
          },
        },

        [`&[data-state=open]`]: {
          '@hover': {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
  },
});

const TriggerText = styled('div', {
  display: 'flex',
  flex: 1,
  minWidth: 0,
  alignItems: 'baseline',
  gap: '$2',
});

const AccordionRoot = styled(AccordionPrimitive.Root, {
  variants: {
    size: {
      0: {},
      1: {
        [`${TriggerButton}`]: {
          paddingY: '$4',
          paddingX: '$6',
        },
        [`${AccordionContent}`]: {
          paddingX: '$6',
          paddingBottom: '$6',
        },
      },
    },
  },
});

AccordionRoot.defaultProps = {
  size: 1,
};

interface TriggerProps {
  children?: React.ReactNode;
  name?: string;
  hasHover?: boolean;
}

function Trigger(props: TriggerProps) {
  const { children, name, hasHover = true } = props;
  return (
    <AccordionPrimitive.Trigger asChild>
      <TriggerButton hasHover={hasHover} variant="base">
        <TriggerText>
          {name && (
            <Text size={{ '@initial': 2, '@bp2:': 3 }} weight="semibold">
              {name}
            </Text>
          )}
          {children}
        </TriggerText>
        <TriggerIndicatorWrapper>
          <ChevronDownIcon size={2} />
        </TriggerIndicatorWrapper>
      </TriggerButton>
    </AccordionPrimitive.Trigger>
  );
}

const AccordionItem = styled(AccordionPrimitive.Item, {
  [`&:first-child ${TriggerButton}`]: {
    borderTop: 'none',
    '@hover': {
      '&:hover': {
        borderTop: 'none',
      },
    },
  },
});

const Accordion = {
  Content: AccordionContent,
  Header: AccordionHeader,
  Item: AccordionItem,
  Root: AccordionRoot,
  Trigger: Trigger,
  TriggerIndicatorWrapper,
};

export default Accordion;
