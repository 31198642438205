import { PageType, ReportReason } from 'types/Report';

interface SendReportProps {
  recaptchaToken: string;
  email: string;
  issue: string;
  url: string;
  originalUrl: string;
  issueReason: ReportReason;
  reporterPublicKey: string;
  reportedPublicKey: string;
  pageType: PageType;
}

export async function sendReport({
  recaptchaToken,
  email,
  issue,
  url,
  originalUrl,
  issueReason,
  reporterPublicKey,
  reportedPublicKey,
  pageType,
}: SendReportProps): Promise<{ done: boolean }> {
  const res = await fetch('/api/admin/report', {
    method: 'POST',
    body: JSON.stringify({
      recaptchaToken,
      email,
      issue,
      url,
      originalUrl,
      issueReason,
      reporterPublicKey,
      reportedPublicKey,
      pageType,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (res.ok) {
    return await res.json();
  }
  throw new Error('An error occurred at this endpoint');
}
