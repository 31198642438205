import Button from 'components/base/Button';
import Link from 'components/base/Link';
import Modal from 'components/base/Modal';
import Text from 'components/base/Text';
import { RadioButton, RadioGroup } from 'components/report/RadioButton';
import { formFieldsData } from 'components/report/data';

import { ReportReason } from 'types/Report';

interface ReportReasonProps {
  onChange: (arg: ReportReason) => void;
  onContinue: () => void;
  reason: ReportReason | null;
}

export default function ReportReasons(props: ReportReasonProps) {
  const { onChange, onContinue, reason } = props;
  return (
    <Modal.Window
      footer={
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={reason === null}
            onClick={onContinue}
          >
            Continue
          </Button>
        </Modal.Footer>
      }
    >
      <Modal.BodyTitle
        title="Report an Issue"
        description={
          <>
            Let us know if you believe there's been a violation of Foundation's{' '}
            <Link href="/terms" variant="primary" hasUnderline>
              Terms of Service
            </Link>
            .
          </>
        }
      />
      <Text weight="semibold" css={{ marginTop: '$7', marginBottom: '$3' }}>
        Reason
      </Text>
      <RadioGroup value={reason || undefined}>
        {formFieldsData.map((d) => (
          <RadioButton
            key={d.type}
            label={d.title}
            value={d.type}
            onChange={onChange}
          />
        ))}
      </RadioGroup>
    </Modal.Window>
  );
}
