import { styled } from '@f8n-frontend/stitches';
import { ComponentProps } from '@stitches/react';

import { countCss, primaryButtonCss } from 'css/button';

import ToggleButton from './ToggleButton';

type ButtonWithCountProps = ComponentProps<typeof ButtonWithCountRoot>;

const Count = styled('div', countCss);

const pressedCss = {
  ...primaryButtonCss,
  [`${Count}`]: {
    backgroundColor: '$white20',
  },
  [`&:disabled ${Count}`]: {
    backgroundColor: '$black60',
  },
};

const ButtonWithCountRoot = styled(ToggleButton, {
  variants: {
    variant: {
      primary: {
        [`${Count}`]: {
          backgroundColor: '$white20',
        },
        '&:disabled': {
          [`${Count}`]: {
            backgroundColor: '$black10',
          },
        },
      },
      outline: {
        [`${Count}`]: {
          backgroundColor: '$black5',
        },
      },
      raised: {
        [`${Count}`]: {
          backgroundColor: '$black5',
        },
      },
      ghost: {
        [`${Count}`]: {
          backgroundColor: '$black5',
        },
      },
      blur: {
        [`${Count}`]: {
          backgroundColor: '$white15',
        },
        '@hover': {
          '&:hover': {
            [`${Count}`]: {
              backgroundColor: '$black5',
            },
          },
        },
        '&:disabled': {
          [`${Count}`]: {
            backgroundColor: '$white15',
          },
        },
      },
    },
    size: {
      0: {
        paddingRight: '$2',
        [`${Count}`]: {
          height: '20px',
          lineHeight: '20px',
          fontSize: '10px',
          paddingX: '$2',
          marginLeft: '$1',
        },
      },
      1: {
        paddingLeft: '$5',
        paddingRight: '$3',
        [`${Count}`]: {
          height: '24px',
          lineHeight: '24px',
          fontSize: '12px',
          paddingX: '10px',
          marginLeft: '$2',
        },
      },
      2: {
        paddingLeft: '$6',
        paddingRight: '$4',
        [`${Count}`]: {
          height: '28px',
          lineHeight: '28px',
          fontSize: '$0',
          paddingX: '$3',
          marginLeft: '$2',
        },
      },
    },
    pressed: {
      true: {
        [`${Count}`]: {
          // Since most variants use the same pressed style, it's fine to
          // default to these styles for <Count /> when pressed
          backgroundColor: '$white20',
          color: '$white100',
        },
      },
    },
  },
  compoundVariants: [
    {
      pressed: true,
      variant: 'outline',
      css: pressedCss,
    },
    {
      pressed: true,
      variant: 'ghost',
      css: pressedCss,
    },
    {
      pressed: true,
      variant: 'raised',
      css: pressedCss,
    },
    {
      pressed: true,
      variant: 'blur',
      css: {
        [`${Count}`]: {
          color: '$black100',
          backgroundColor: '$black5',
        },
        '@hover': {
          '&:hover': {
            [`${Count}`]: {
              color: '$black100',
              backgroundColor: '$black10',
            },
          },
        },
      },
    },
  ],
});

const ButtonWithCount = {
  Root: ButtonWithCountRoot,
  Count: Count,
};

export type { ButtonWithCountProps };
export default ButtonWithCount;
