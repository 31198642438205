import { ReactNode } from 'react';

import Modal from 'components/base/Modal';

import useModal from 'hooks/use-modal';

import { ModalKey } from 'types/modal';

interface ModalContainerProps {
  children: ReactNode;
  modalKey: ModalKey;
}

/** @deprecated use Modal.Root directly instead. */
export default function ModalContainer(props: ModalContainerProps) {
  const { children, modalKey } = props;

  const { activeModal, setModal } = useModal();

  const isOpen = activeModal ? modalKey === activeModal['type'] : false;

  return (
    <Modal.Root open={isOpen} onOpenChange={() => setModal(null)}>
      {children}
    </Modal.Root>
  );
}
