import Link from 'components/base/Link';

import { helpCenterPaths } from 'utils/router';

import { ReportReason } from 'types/Report';

type FieldData = {
  type: ReportReason;
  title: string;
  description: () => JSX.Element;
  originalArtworkField?: boolean;
  originalFieldLabel?: string;
  noForm?: boolean;
};

export const formFieldsData: FieldData[] = [
  {
    type: 'ViolentContent',
    title: 'Violent or Graphic Content',
    description: () => (
      <>
        Foundation does not tolerate any form of Violent or Graphic Content.{' '}
        <Link
          hasUnderline
          href={helpCenterPaths.policies.violentOrGraphicContent}
          target="_blank"
          variant="primary"
        >
          Learn more
        </Link>
      </>
    ),
  },
  {
    type: 'HatefulContent',
    title: 'Hateful or Abusive Content',
    description: () => (
      <>
        Foundation does not tolerate any form of Hateful or Abusive Content.{' '}
        <Link
          hasUnderline
          href={helpCenterPaths.policies.hatefulOrAbusiveContent}
          target="_blank"
          variant="primary"
        >
          Learn more
        </Link>
      </>
    ),
  },
  {
    type: 'SexualContent',
    title: 'Graphic Sexual Content',
    description: () => (
      <>
        Foundation does not tolerate any form of Graphic Sexual Content.{' '}
        <Link
          hasUnderline
          href={helpCenterPaths.policies.adultContent}
          target="_blank"
          variant="primary"
        >
          Learn more
        </Link>
      </>
    ),
  },
  {
    type: 'SuspiciousActivity',
    title: 'Other Suspicious Activity',
    description: () => (
      <>Foundation does not tolerate any form of suspicious activity.</>
    ),
  },
  {
    type: 'StolenArtwork',
    title: 'Counterfeit Artwork',
    description: () => (
      <>
        Foundation does not tolerate any form of plagiarism or counterfeit
        artwork.{' '}
        <Link
          hasUnderline
          href={helpCenterPaths.policies.counterfitContent}
          target="_blank"
          variant="primary"
        >
          Learn more
        </Link>
      </>
    ),
    originalArtworkField: true,
    originalFieldLabel: 'URL of original artwork',
  },
  {
    type: 'UserMisrepresentation',
    title: 'User Misrepresentation',
    description: () => (
      <>
        Foundation does not tolerate any form of User Misrepresentation.{' '}
        <Link
          hasUnderline
          href={helpCenterPaths.policies.misleadingIdentities}
          target="_blank"
          variant="primary"
        >
          Learn more
        </Link>
      </>
    ),
    originalArtworkField: true,
    originalFieldLabel: 'URL of the Impersonated Artist/ Person',
  },
  {
    type: 'IP',
    title: 'Intellectual Property',
    description: () => (
      <>
        Foundation takes claims of intellectual property infringement seriously.
        For more information and instructions on how to report infringement,
        please visit our{' '}
        <Link
          hasUnderline
          href={helpCenterPaths.aboutDMCA}
          target="_blank"
          variant="primary"
        >
          Intellectual Property Policy
        </Link>
        .
      </>
    ),
    noForm: true,
  },
];
