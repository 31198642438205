import { AdminIcon } from '@f8n/icons';
import React from 'react';

import Accordion from 'components/base/Accordion';
import Box from 'components/base/Box';
import Modal from 'components/base/Modal';
import Text from 'components/base/Text';
import ModalContainer from 'components/modals/common/ModalContainer';

type Tool = {
  id: string;
  name: string;
  ui: React.ReactNode;
};

type Option = Tool | null;

type AdminToolsModalProps = {
  tools: Option[];
};

const isTool = (option: Option): option is Tool => option !== null;

export default function AdminToolsModal(props: AdminToolsModalProps) {
  const { tools } = props;

  return (
    <ModalContainer modalKey="ADMIN_TOOLS">
      <Modal.Content
        header={
          <Modal.Header>
            {/* hack div to push icon header to center */}
            <Box />
            <Box css={{ display: 'flex', alignItems: 'center', gap: '$2' }}>
              <AdminIcon size={1} />
              <Text weight="semibold">Admin tools</Text>
            </Box>
          </Modal.Header>
        }
        size={0}
      >
        <Accordion.Root type="multiple">
          {tools.filter(isTool).map(({ id, name, ui }) => (
            <Accordion.Item key={id} value={id}>
              <Accordion.Header>
                <Accordion.Trigger name={name} />
              </Accordion.Header>
              <Accordion.Content>{ui}</Accordion.Content>
            </Accordion.Item>
          ))}
        </Accordion.Root>
      </Modal.Content>
    </ModalContainer>
  );
}
