import { onGridPx } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import { useField } from 'formik';
import React, { InputHTMLAttributes } from 'react';
import AutoExpand from 'react-expanding-textarea';

import Field from 'components/base/Field';
import Flex from 'components/base/Flex';
import { Input } from 'components/base/InputV3Base';

import { isNumberType } from 'utils/helpers';

import { PartialPick } from 'types/utils';

type TextAreaProps = InputHTMLAttributes<HTMLTextAreaElement>;

type RequiredTextArea = Pick<TextAreaProps, 'name' | 'maxLength' | 'onChange'>;
type OptionalTextArea = PartialPick<
  TextAreaProps,
  'placeholder' | 'required' | 'type' | 'disabled'
>;

interface TextAreaFieldProps extends RequiredTextArea, OptionalTextArea {
  rows: number | undefined;
  label: string;
  tip?: string;
  name: string;
}

const AutoExpandInput = styled(AutoExpand, Input, {
  resize: 'none',
  lineHeight: '$2',
  // Input will auto-expand, up to this limit
  maxHeight: onGridPx(75),
  whiteSpace: 'pre-wrap',
  overflow: 'auto',
  paddingY: '$4',
  display: 'block',
});

/**
 * @deprecated use TextAreaBase instead
 */
function TextAreaField<T extends string>(props: TextAreaFieldProps) {
  const {
    name,
    placeholder,
    maxLength,
    label,
    required = false,
    disabled = false,
    rows,
    tip,
  } = props;

  const [field, meta] = useField<T>(name);

  return (
    <TextAreaBase
      required={required}
      label={label}
      placeholder={placeholder}
      maxLength={maxLength}
      disabled={disabled}
      rows={rows}
      error={meta.error}
      touched={meta.touched}
      tip={tip}
      {...field}
    />
  );
}

type TextFieldAreaBaseProps = TextAreaFieldProps & {
  error: string | undefined;
  touched: boolean;
  required?: boolean | undefined;
  value?: string;
};

export const TextAreaBase = React.forwardRef<
  HTMLTextAreaElement,
  TextFieldAreaBaseProps
>(function TextAreaBase(props, ref) {
  const {
    name,
    placeholder,
    label,
    error,
    touched,
    required = false,
    disabled = false,
    tip,
    rows,
    maxLength,
    ...rest
  } = props;

  const currentLength = props.value ? props.value.length : 0;

  const getTip = () => {
    if (isNumberType(maxLength)) {
      return (
        <Flex css={{ justifyContent: 'space-between' }}>
          <span>{tip}</span>
          {maxLength > 0 ? (
            <span>
              {currentLength} / {maxLength}
            </span>
          ) : null}
        </Flex>
      );
    }

    if (tip) {
      return <span>{tip}</span>;
    }

    return null;
  };

  return (
    <Field
      required={required}
      htmlFor={name}
      label={label}
      tip={getTip()}
      touched={touched}
      error={error}
      disabled={disabled}
    >
      <AutoExpandInput
        {...rest}
        name={name}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        maxLength={maxLength}
        rows={rows}
        id={name}
        ref={ref}
      />
    </Field>
  );
});

const TextArea = Object.assign(TextAreaBase, {
  Formik: TextAreaField,
});

export default TextArea;
