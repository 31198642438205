import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useQueryEffects } from 'hooks/react-query';
import { isAnyTrue, noop, notEmptyOrNil } from 'utils/helpers';

import { NftFilters } from 'types/Nft';

export const INITIAL_NFT_FILTERS: NftFilters = {
  attributes: [],
  collections: {},
  creatorPublicKey: null,
  hasFilters: false,
  marketAvailability: null,
  media: null,
  searchTerm: '',
  sortOrder: 'DEFAULT',
};

type NftFiltersApi = {
  filters: NftFilters;
  resetAttributesFilter(): void;
  resetCollectionsFilter(): void;
  resetCreatorFilter(): void;
  resetFilters(): void;
  resetMaketAvailabilityFilter(): void;
  setFilters(filters: Partial<NftFilters>): void;
  setCollectionFilter(collectionId: string, isFiltered: boolean): void;
  setCreatorFilter(creatorPublicKey: string): void;
};

// use react-query as a cache for the search variables
export function useNftFilters(
  id: string,
  options: {
    onSuccess: (filters: NftFilters) => void;
    enabled: boolean;
  } = {
    onSuccess: noop,
    enabled: true,
  }
): NftFiltersApi {
  const queryClient = useQueryClient();
  const cacheKey = useNftFilters.getKey(id);

  const nftFiltersQuery = useQuery({
    enabled: options.enabled,
    initialData: INITIAL_NFT_FILTERS,
    queryKey: cacheKey,
  });

  const { data: filters = INITIAL_NFT_FILTERS } = nftFiltersQuery;

  useQueryEffects(nftFiltersQuery, {
    onSuccess: options.onSuccess,
  });

  const setFilters: NftFiltersApi['setFilters'] = (nextState) => {
    queryClient.setQueryData<NftFilters>(cacheKey, (prevState: NftFilters) => {
      const filters = { ...prevState, ...nextState };
      const hasFilters = isAnyTrue([
        notEmptyOrNil(filters.marketAvailability),
        notEmptyOrNil(filters.attributes),
        notEmptyOrNil(filters.creatorPublicKey),
        notEmptyOrNil(Object.values(filters.collections).some((v) => v)),
      ]);

      let marketAvailability = prevState.marketAvailability;

      if (nextState.marketAvailability === undefined) {
        marketAvailability = prevState.marketAvailability;
      } else if (
        nextState.marketAvailability === null ||
        nextState.marketAvailability === prevState.marketAvailability
      ) {
        marketAvailability = null;
      } else {
        marketAvailability = nextState.marketAvailability;
      }

      return {
        ...filters,
        hasFilters,
        marketAvailability,
      };
    });
  };

  const setCollectionFilter: NftFiltersApi['setCollectionFilter'] = (
    collectionId,
    isFiltered
  ) => {
    setFilters({
      collections: {
        ...filters.collections,
        [collectionId]: isFiltered,
      },
    });
  };

  const setCreatorFilter: NftFiltersApi['setCreatorFilter'] = (
    creatorPublicKey
  ) => {
    setFilters({
      creatorPublicKey,
    });
  };

  const resetAttributesFilter = () => {
    setFilters({ attributes: INITIAL_NFT_FILTERS.attributes });
  };

  const resetCreatorFilter = () => {
    setFilters({ creatorPublicKey: INITIAL_NFT_FILTERS.creatorPublicKey });
  };

  const resetCollectionsFilter = () => {
    setFilters({ collections: INITIAL_NFT_FILTERS.collections });
  };

  const resetMaketAvailabilityFilter = () => {
    setFilters({ marketAvailability: null });
  };

  const resetFilters: NftFiltersApi['resetFilters'] = () => {
    setFilters(INITIAL_NFT_FILTERS);
  };

  return {
    filters,
    resetAttributesFilter,
    resetCollectionsFilter,
    resetCreatorFilter,
    resetFilters,
    resetMaketAvailabilityFilter,
    setCollectionFilter,
    setCreatorFilter,
    setFilters,
  };
}

useNftFilters.getKey = (id: string) => ['NftFilters', id];
