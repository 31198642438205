import { always, cond, T } from 'ramda';
import { useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Button from 'components/base/Button';
import Modal from 'components/base/Modal';
import ReportForm from 'components/report/ReportForm';
import ReportReasons from 'components/report/ReportReasons';

import useUserByPublicKey from 'hooks/queries/api/use-user-by-public-key';
import useModal from 'hooks/use-modal';

import { PageType, ReportReason } from 'types/Report';
import { Maybe } from 'types/utils';

import ModalContainer from './common/ModalContainer';

interface ReportModalProps {
  publicKey: string;
  reportedPublicKey: string;
  pageType: PageType;
}

interface ReportFormContainerProps {
  email?: Maybe<string> | undefined;
  publicKey?: string;
  reportedPublicKey: string;
  pageType: PageType;
  onClose: () => void;
}

interface SubmittedProps {
  onClose: () => void;
}

function Submitted(props: SubmittedProps) {
  const { onClose } = props;
  return (
    <Modal.Content
      footer={
        <Modal.Footer>
          <Button variant="primary" onClick={onClose}>
            Okay
          </Button>
        </Modal.Footer>
      }
    >
      <Modal.BodyTitle
        title="Report Submitted"
        description="Thank you for submitting a report. Foundation's Trust and Safety team will review the report, and reach out to you if they need additional information."
      />
    </Modal.Content>
  );
}

function ReportFormContainer(props: ReportFormContainerProps) {
  const {
    email = '',
    publicKey = '',
    reportedPublicKey = '',
    pageType,
    onClose,
  } = props;
  const [step, setStep] = useState(0);
  const [reportReason, setReason] = useState<ReportReason | null>(null);

  const handleOnChange = (value: ReportReason) => {
    setReason(value);
  };

  const handleContinue = () => {
    setStep(1);
  };

  const handleSubmit = () => {
    setStep(2);
  };

  const handleBack = () => {
    setStep(0);
  };

  return cond([
    [() => step === 2, always(<Submitted onClose={onClose} />)],
    [
      () => step === 1,
      always(
        <ReportForm
          email={email}
          publicKey={publicKey}
          onSubmit={handleSubmit}
          onBack={handleBack}
          onClose={onClose}
          reason={reportReason as ReportReason}
          pageType={pageType}
          reportedPublicKey={reportedPublicKey}
        />
      ),
    ],
    [
      T,
      always(
        <ReportReasons
          onChange={handleOnChange}
          onContinue={handleContinue}
          reason={reportReason}
        />
      ),
    ],
  ])();
}

export default function ReportModal(props: ReportModalProps) {
  const { publicKey, reportedPublicKey, pageType } = props;
  const { activeModal, setModal } = useModal();

  const isOpen = activeModal ? activeModal.type === 'REPORT' : false;

  const { data: user } = useUserByPublicKey({ publicKey }, { enabled: isOpen });

  const handleClose = () => {
    setModal(null);
  };

  return (
    <ModalContainer modalKey="REPORT">
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string}
          >
            <ReportFormContainer
              email={user?.email}
              publicKey={publicKey}
              reportedPublicKey={reportedPublicKey}
              pageType={pageType}
              onClose={handleClose}
            />
          </GoogleReCaptchaProvider>
        </Modal.PositionOverlay>
      </Modal.Portal>
    </ModalContainer>
  );
}
